<template>
  <div class="container-fluid top-menos">
    <loading-overlay
      :active="loading"
      :is-full-page="true"
      loader="bars"
    />
    <CModalExtended
      :title="item.CarrierClientName+': '+item.TotalTimeHIncidenceByCarrier+' HRS'"
      color="dark"
      size="xl"
      class="modal-extended"
      @update:show="closeModal()"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
    >
      <CRow>
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            size="lg"
            :fields="fields"
            :items="computedListHold"
            :items-per-page="5"
            column-filter
            pagination
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :noItemsView="tableText.noItemsViewText"
            :loading="loading"
            sorter
          >
            <template #loading>
              <loading/>
            </template>
            <template #TotalTime="{item}">
                <td class="text-center">
                  {{item.TotalTimeH}}
                </td>
            </template>
          </dataTableExtended>
        </CCol>
      </CRow>
      <template #footer>
        <CButton color="wipe" @click="closeModal(false)">
          <CIcon name="x" />&nbsp; {{$t('button.exit')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';

function data() {
  return {
    IncidenceDetail: [],

    modalActive: false,
    loading: false,
  };
}

function closeModal() {
  this.IncidenceDetail = [];
  this.$emit('close-Modal');
}

function computedListHold() {
  if (this.IncidenceDetail.length == 0) {
    return [];
  };
  let _lang = this.$i18n.locale;
  return this.IncidenceDetail.map((item) => {
    return {
      ...item,
      IncidenceGpoName: _lang=='en' ? item.IncidenceGpoNameEn : item.IncidenceGpoNameEs,
      IncidenceName: _lang=='en' ? item.IncidenceNameEn : item.IncidenceNameEs,
    };
  });
}

function fields(){
  return [
    { label: '#', key: 'IdX', _classes: 'text-center', _style: 'width:4%', filter: false},
    { label: this.$t('label.IncidenceGroup'), key: 'IncidenceGpoName', _classes: 'text-uppercase', _style: 'width:33%;',},
    { label: this.$t('label.incidence'), key: 'IncidenceName', _classes: 'text-uppercase', _style:'width:33%' },
    { label: this.$t('label.time'), key: 'TotalTime', _classes: 'text-center', _style:'width:30%' },
  ];
}

function formatNumber(number) {
  if (number == "") return "0,00";
  let format = Number.parseFloat(number).toFixed(2);
  format = format.replace(".", ",");

  return format.replace(/\d(?=(\d{3})+\,)/g, "$&.");
}

export default {
  name: 'modal-transport',
  props: { modal: Boolean, item: Object },
  data,
  mixins: [
    GeneralMixin, 
  ],
  directives: UpperCase,
  methods: {
    formatNumber,
    closeModal,
  },
  computed: {
    computedListHold,
    fields,
  },
  watch: {
    modal: async function(val) {
      this.modalActive = val;
      if(val == true){
        this.IncidenceDetail = this.item.IncidenceDetailJson;
      }
    },
  },
  
};
</script>